@import url(https://fonts.googleapis.com/css?family=Roboto);
html {
  background-color: #353535;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.cursor {
  cursor: default;
}

.earth {
  padding: 0 35%;
}

#main {
  padding: 48px 0 0 0;
}

#main .hero-body {
  padding: 48px 12px 12px 12px;
}

.navbar .navbar-menu .navbar-end .navbar-item {
  color: #ffffff;
}

.navbar .navbar-menu .navbar-end .navbar-item:hover {
  background-color: #118FE4;
  color: #ffffff;
}

/* unvisited link */
footer a:link {
  color: hsl(0, 0%, 96%);
}

/* visited link */
footer a:visited {
  color: hsl(0, 0%, 96%);
}

/* mouse over link */
footer a:hover {
  color: hsl(204, 86%, 53%);
}

/* selected link */
footer a:active {
  color: hsl(217, 71%, 53%);
}
/* 
footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
} */

.cursor {
  cursor: default;
}


.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* .page {
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
} */

.fade-appear {
  opacity: 0;
  z-index: 1;
}

.fade-appear.fade-appear-active {
  opacity: 1;
  -webkit-transition: opacity 1000ms linear;
  transition: opacity 1000ms linear;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-enter.fade-enter-active {
  opacity: 0;
  -webkit-transition: opacity 300ms linear;
  transition: opacity 300ms linear;
}
.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  -webkit-transition: opacity 150ms linear;
  transition: opacity 150ms linear;
}

.example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  -webkit-transition: opacity 500ms ease-in;
  transition: opacity 500ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  -webkit-transition: opacity 300ms ease-in;
  transition: opacity 300ms ease-in;
}

.example-appear {
  opacity: 0.01;
}

.example-appear.example-appear-active {
  opacity: 1;
  -webkit-transition: opacity 0.5s ease-in;
  transition: opacity 0.5s ease-in;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

