/* unvisited link */
footer a:link {
  color: hsl(0, 0%, 96%);
}

/* visited link */
footer a:visited {
  color: hsl(0, 0%, 96%);
}

/* mouse over link */
footer a:hover {
  color: hsl(204, 86%, 53%);
}

/* selected link */
footer a:active {
  color: hsl(217, 71%, 53%);
}
/* 
footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
} */
