.cursor {
  cursor: default;
}

.earth {
  padding: 0 35%;
}

#main {
  padding: 48px 0 0 0;
}

#main .hero-body {
  padding: 48px 12px 12px 12px;
}
